<script>
import { required } from "vuelidate/lib/validators";
import validationMessages from '@/components/validations';
import Ticketing from '@/services/Ticketing';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

export default {
    components: { validationMessages, flatPickr },
    props: [],

    data() {
        return {
            csrf_token: localStorage.getItem('csrf_token'),
            season_id: '',
            remote_season_id: '',
            season_name: '',
            new_sale_open: 'N',
        current_season: 0, 
            start_sale_date: '',
            end_sale_date: '',
            submitted: false,
            showModal: false,
            tryingToEdit: false,
            modalKey: 0,
            tryingToSubmit: false,
            showLoader: false,
            remoteSeasonOptions: [],
        };
    },

    validations: {
        season_id: { required },
        remote_season_id: { required },
        season_name: { required },
        start_sale_date: { required },
        end_sale_date: { required },
    },
    mounted() {
        this.getSeasonProducts();
    },

    methods: {
        async getSeasonProducts() {
            try {
                const response = await Ticketing.getSeasonProducts();
                this.remoteSeasonOptions = response.data.data.map(season => ({
                    value: season.product_id,
                    text: season.name
                }));
            } catch (error) {
                console.error('Error fetching remote seasons:', error);
            }
        },

        async addSeasonSetup() {
            this.tryingToSubmit = true;
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.tryingToSubmit = false;
                return;
            }
            this.current_season = this.current_season ? 1 : 0;

            try {
                await Ticketing.addSeasonSetup({
                    season_id: this.season_id,
                    remote_season_id: this.remote_season_id,
                    season_name: this.season_name,
                    new_sale_open: this.new_sale_open,
                    current_season: this.current_season,
                    start_sale_date: this.start_sale_date,
                    end_sale_date: this.end_sale_date,
                }).then((response) => {
                    this.successmsg("Season saved");
                    this.closeModal();
                }).catch((error) => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Season not saved");
                }).finally(() => {
                    this.refreshData();
                    this.tryingToSubmit = false;
                    this.resetForm(); 
                });
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg("Season not saved");
                this.tryingToSubmit = false;
            }
        },

        callModalAddSeasonSetup() {
            this.showModal = true;
            this.resetForm();
        },

        resetForm() {
            this.season_id = '';
            this.remote_season_id = '';
            this.season_name = '';
            this.new_sale_open = 'N'; 
        this.current_season = 0;
            this.start_sale_date = '';
            this.end_sale_date = '';
            this.submitted = false;
            this.$v.$reset();
        },

        refreshData() {
            this.$emit('onRefresh'); //event from parent
        },

        closeModal() {
            this.showModal = false;
            this.tryingToSubmit = false;
            this.$emit('close-modal');
            this.resetForm();
        },
    }
};


</script>

<template>
     <b-modal :key="modalKey" v-model="showModal" id="add_season_setup" title="Add Season" title-class="font-18" centered  @before-open="resetForm">
         <form @submit.prevent="addSeasonSetup()">
            <div class="row">
    <b-card-text>
                    <div class="row">
 <b-form-group label="Season ID" label-for="formrow-name-input" class="mb-3">
                                <b-form-input  v-model.trim="season_id"  :class="{ 'is-invalid': submitted && $v.season_id.$error}"></b-form-input>
                                <validationMessages v-if="submitted" :fieldName="'Season Id'" :validationName="$v.season_id"></validationMessages>
                           </b-form-group>

                             <b-form-group label="Remote Season Id" label-for="formrow-name-input" class="mb-3">
                <b-form-select
                    v-model="remote_season_id"
                    :options="remoteSeasonOptions"
                    id="remote_season_id"
                    aria-describedby="name-feedback"
                    :class="{ 'is-invalid': submitted && $v.remote_season_id.$error }"
                    class="form-select"
                >
                </b-form-select>
                <validationMessages v-if="submitted && $v.remote_season_id.$error" :fieldName="'Remote Season Id'" :validationName="$v.remote_season_id"></validationMessages>
            </b-form-group>
     <b-form-group label="Season Name" label-for="formrow-display_name-input" class="mb-3">
                                  <b-form-input
                                          v-model.trim="season_name"
                                          type="text"
                                          id="season_name"
                                          aria-describedby="display_name-feedback"
                                          :class="{ 'is-invalid': submitted && $v.season_name.$error}"
                                  >
                                  </b-form-input>
                                  <validationMessages v-if="submitted" :fieldName="'Season Name'" :validationName="$v.season_name"></validationMessages>
                              </b-form-group>
                      <b-form-group  class="mb-3">
                                  <div class="form-check square-checkbox">
                                      <input
                                              class="form-check-input"
                                              type="checkbox"
                                              id="new_sale_open_checkbox"
                                      v-model="new_sale_open"
                                      true-value="Y"
                                      false-value="N"
                                      >
                                      <label class="form-check-label" for="new_sale_open_checkbox">New Sale Open</label>
                                  </div>
                              </b-form-group>
                               <b-form-group  class="mb-3">
                                  <div class="form-check square-checkbox">
                                      <input
                                              class="form-check-input"
                                              type="checkbox"
                                              id="current_season_checkbox"
                                      v-model="current_season"
                                      true-value="1"
                                      false-value="0"
                                      >
                                      <label class="form-check-label" for="current_season_checkbox">Current Season</label>
                                  </div>
                              </b-form-group>
                             
                              
                              <b-form-group label="Start Sale" label-for="formrow-valid_to-input" class="mb-3"> <flat-pickr
                    v-model="start_sale_date"
                    class="form-control mb-2"
                    name="start_sale_date"
                    :class="{ 'is-invalid': submitted && $v.start_sale_date.$error }"
                />
                <validationMessages v-if="submitted && $v.start_sale_date.$error" :fieldName="'Start Sale'" :validationName="$v.start_sale_date"></validationMessages>
            </b-form-group>
                               <b-form-group label="End Sale" label-for="formrow-valid_to-input" class="mb-3">
                                 <flat-pickr
                                      v-model="end_sale_date"
                                      class="form-control mb-2"
                                      name="end_sale_date"
                                      :class="{ 'is-invalid': submitted && $v.end_sale_date.$error }"
                                 />
                                 <validationMessages v-if="submitted && $v.end_sale_date.$error" :fieldName="'End Sale'" :validationName="$v.end_sale_date"></validationMessages>
                              </b-form-group>

                              <input type="hidden" name="csrf_token" v-model="csrf_token">
      </div>
                </b-card-text>

            </div>
</form>
  <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addSeasonSetup" :disabled="tryingToSubmit || showLoader">
                 <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
            </b-button>
        </template>
    </b-modal>
</template>

