<script>
    import Ticketing from "@/services/Ticketing";


    export default {
        components: {},
        props: {
            modalData: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                display_name: '',
                name: '',
                product_id: '',
                type: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                modalKey: 0,

            };
        },

        mounted() {

            this.$nextTick(() => {
                this.setPrimaryCheckedBasedOnActiveStatus();
            });
        },
        methods: {
            async editSeasonProduct() {
                this.tryingToEdit = true;
                this.submitted = true;
                try {
                    await Ticketing.updateSeasonProduct({
                        product_id: this.modalData.product_id,
                        name: this.modalData.name,
                        display_name: this.modalData.display_name,
                        type: this.modalData.type,
//                        active: this.active, // Use the selected state directly
//                        active: this.isActive ? 1 : 0,
                        active: this.modalData.active ,


                    }).then((response) => {
                        this.successmsg("Changes have been saved");
                        this.closeModal();
                    }).catch((error) => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Changes not saved");
                    }).finally(() => {
                        this.refreshData();
                        this.tryingToEdit = false;
                    });
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Changes not saved");
                }

                this.tryingToEdit = false;
            },
            resetSelect() {
//                this.modalData.type = null;
            },
            setPrimaryCheckedBasedOnActiveStatus() {
                this.modalData.active = this.modalData.active === 1 ? 1 : 0;
            },

            refreshData() {
                this.$emit('onRefresh'); // Event from parent
            },

            closeModal() {
                this.showModal = false;
                this.$emit('close-modal');
            },
        },
    };
</script>


<template>
    <b-modal :key="modalKey" v-model="showModal" id="edit_season_product" title="Edit Season Product" title-class="font-18" centered @shown="setPrimaryCheckedBasedOnActiveStatus()" @hide="resetSelect">
        <form @submit.prevent="editSeasonProduct">

            <b-form-checkbox-group>
                <input type="hidden" name="remote_match_id" v-model="modalData.product_id">


            </b-form-checkbox-group>
            <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                <b-form-input
                        v-model.trim="modalData.name"
                        type="text"
                        id="name"
                        readonly
                        aria-describedby="name-feedback"
                >
                </b-form-input>
            </b-form-group>
            <b-form-group label="Display Name" label-for="formrow-display_name-input" class="mb-3">
                <b-form-input
                        v-model.trim="modalData.display_name"
                        type="text"
                        id="display_name"
                        aria-describedby="display_name-feedback"
                >
                </b-form-input>
            </b-form-group>
                <b-form-group label="Select Type" label-for="event" class="mb-3" :key="modalKey">

                <b-form-select class="form-control"  v-model="modalData.type">
                    <option value="season">Season</option>
                    <option value="parking">Parking</option>
                    <option value="bus">Bus</option>
                    <option value="other">Other</option>
                </b-form-select>
            </b-form-group>

            <b-form-group  class="mb-3">
                <div class="form-check square-checkbox">
                    <input
                            class="form-check-input"
                            type="checkbox"
                            id="activeCheckbox"
                    v-model="modalData.active"
                    true-value="1"
                    false-value="0"
                    >
                    <label class="form-check-label" for="activeCheckbox">Active</label>
                </div>
            </b-form-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editSeasonProduct" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>