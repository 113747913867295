<script>
 import Ticketing from "@/services/Ticketing";
import flatPickr from "vue-flatpickr-component";
import validationMessages from '@/components/validations';
import { required } from "vuelidate/lib/validators";

export default {
    components: { flatPickr, validationMessages },
    props: {
        modalData: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            csrf_token: localStorage.getItem('csrf_token'),
            submitted: false,
            showModal: false,
            tryingToEdit: false,
            modalKey: 0,
            remoteSeasonOptions: [],
        };
    },
    validations() {
        return {
            modalData: {
                season_name: { required },
                start_sale_date: { required },
                end_sale_date: { required },
                remote_season_id: { required },
            },
        };
    },

    mounted() {
        this.getSeasonProducts();
    },
    methods: {
        async getSeasonProducts() {
            try {
                const response = await Ticketing.getSeasonProducts();
                this.remoteSeasonOptions = response.data.data.map(season => ({
                    value: season.product_id,
                    text: season.name
                }));
            } catch (error) {
                console.error('Error fetching remote seasons:', error);
            }
        },
        async editSeasonSetup() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.tryingToEdit = true;
            try {
                await Ticketing.updateSeasonSetup({
                    season_id: this.modalData.season_id,
                    remote_season_id: this.modalData.remote_season_id,
                    season_name: this.modalData.season_name,
                    new_sale_open: this.modalData.new_sale_open,
                    current_season: this.modalData.current_season,
                    start_sale_date: this.modalData.start_sale_date,
                    end_sale_date: this.modalData.end_sale_date,
                }).then((response) => {
                    this.successmsg("Changes have been saved");
                    this.closeModal();
                }).catch((error) => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Changes not saved");
                }).finally(() => {
                    this.refreshData();
                    this.tryingToEdit = false;
                });
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg("Changes not saved");
                this.tryingToEdit = false;
            }
        },
        refreshData() {
            this.$emit('onRefresh'); // Event from parent
        },
        closeModal() {
            this.showModal = false;
            this.$emit('close-modal');
        },
    },
};
</script>


<template>
    <b-modal :key="modalKey" v-model="showModal" id="edit_season_setup" title="Edit Season Setup" title-class="font-18" centered>
        <form @submit.prevent="editSeasonSetup">
            <b-form-group label="Season Id" label-for="formrow-name-input" class="mb-3">
                <b-form-input
                    v-model.trim="modalData.season_id"
                    type="text"
                    id="season_id"
                    aria-describedby="name-feedback"
                    readonly
                >
                </b-form-input>
            </b-form-group>

            <b-form-group label="Remote Season Id" label-for="formrow-name-input" class="mb-3">
                <b-form-select
                    v-model="modalData.remote_season_id"
                    :options="remoteSeasonOptions"
                    id="remote_season_id"
                    aria-describedby="name-feedback"
                    :class="{ 'is-invalid': submitted && $v.modalData.remote_season_id.$error }"
                    class="form-select"
                >
                </b-form-select>
                <validationMessages v-if="submitted && $v.modalData.remote_season_id.$error" :fieldName="'Remote Season Id'" :validationName="$v.modalData.remote_season_id"></validationMessages>
            </b-form-group>

            <b-form-group label="Season Name" label-for="formrow-display_name-input" class="mb-3">
                <b-form-input
                    v-model.trim="modalData.season_name"
                    type="text"
                    id="season_name"
                    aria-describedby="display_name-feedback"
                    :class="{ 'is-invalid': submitted && $v.modalData.season_name.$error }"
                >
                </b-form-input>
                <validationMessages v-if="submitted && $v.modalData.season_name.$error" :fieldName="'Season Name'" :validationName="$v.modalData.season_name"></validationMessages>
            </b-form-group>

            <b-form-group class="mb-3">
                <div class="form-check square-checkbox">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="new_sale_open_checkbox"
                        v-model="modalData.new_sale_open"
                        true-value="Y"
                        false-value="N"
                    >
                    <label class="form-check-label" for="new_sale_open_checkbox">New Sale Open</label>
                </div>
            </b-form-group>

            <b-form-group class="mb-3">
                <div class="form-check square-checkbox">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="current_season_checkbox"
                        v-model="modalData.current_season"
                        true-value="1"
                        false-value="0"
                    >
                    <label class="form-check-label" for="current_season_checkbox">Current Season</label>
                </div>
            </b-form-group>

            <b-form-group label="Start Sale" label-for="formrow-valid_to-input" class="mb-3">
                <flat-pickr
                    v-model="modalData.start_sale_date"
                    class="form-control mb-2"
                    name="start_sale_date"
                    :class="{ 'is-invalid': submitted && $v.modalData.start_sale_date.$error }"
                />
                <validationMessages v-if="submitted && $v.modalData.start_sale_date.$error" :fieldName="'Start Sale'" :validationName="$v.modalData.start_sale_date"></validationMessages>
            </b-form-group>

            <b-form-group label="End Sale" label-for="formrow-valid_to-input" class="mb-3">
                <flat-pickr
                    v-model="modalData.end_sale_date"
                    class="form-control mb-2"
                    name="end_sale_date"
                    :class="{ 'is-invalid': submitted && $v.modalData.end_sale_date.$error }"
                />
                <validationMessages v-if="submitted && $v.modalData.end_sale_date.$error" :fieldName="'End Sale'" :validationName="$v.modalData.end_sale_date"></validationMessages>
            </b-form-group>

            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editSeasonSetup" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change
            </b-button>
        </template>
    </b-modal>
</template>
