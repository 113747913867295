<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Ticketing from "@/services/Ticketing";
import Swal from "sweetalert2";
import ModalEditSeasonProduct from "@/components/modals/ticketing/modalEditSeasonProduct";
import ModalEditSeasonSetup from "@/components/modals/ticketing/modalEditSeasonSetup";
import ModalAddSeasonSetup from "@/components/modals/ticketing/modalAddSeasonSetup";

export default {
    components: {
        Layout,
        PageHeader,
        ModalEditSeasonProduct,
        ModalEditSeasonSetup,
        ModalAddSeasonSetup,
    },
    page: {
        title: "Season Setup",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            titleProducts: "Season Products",
            title: "Season Setup",
            items: [
                {
                    text: "Ticketing",
                },
                {
                    text: "Season Products",
                    active: true,
                    href: "/ticketing/season_products",
                },
            ],
            isBusy: false,
            isBusySetup: false,
            error: null,
            showModal: false,
            filteredData: [],
            modalData: {},
            link_id: null,
            tableData: [],
            tableDataSetup: [],
            totalRows: 0,
            totalRowsSetup: 0,
            currentPage: 1,
            currentPageSetup: 1,
            perPage: 10,
            perPageSetup: 10,
            pageOptions: [10, 25, 50, 100],
            pageOptionsSetup: [10, 25, 50, 100],
            filter: null,
            filterSetup: null,
            filterOn: [],
            sortBy: "published",
            sortBySetup: "season_id",
            sortDesc: true,
            fields: [
                {
                    key: "name",
                    label: "Name",
                    sortable: true,
                },
                {
                    key: "display_name",
                    label: "Display Name",
                    sortable: false,
                },
                {
                    key: "type",
                    label: "Type",
                    sortable: false,
                },
                {
                    key: "active",
                    label: "Active",
                    sortable: true,
                },
                "action",
            ],
            fieldsSetup: [
                {
                    key: "season_id",
                    label: "Season Id",
                    sortable: true,
                },
                {
                    key: "remote_season_id",
                    label: "Remote Season Id",
                    sortable: true,
                },
                {
                    key: "season_name",
                    label: "Season Name",
                    sortable: true,
                },
                {
                    key: "new_sale_open",
                    label: "New Sale Open",
                    sortable: true,
                },
                {
                    key: "current_season",
                    label: "Current Season",
                    sortable: true,
                },
                {
                    key: "start_sale_date",
                    label: "Start Sale Date",
                    sortable: true,
                },
                {
                    key: "end_sale_date",
                    label: "End Sale Date",
                    sortable: true,
                },
                "action",
            ],
            filterType: "",
            filterActive: "",
            typeOptions: [{ value: "", text: "All Types" }],
            activeOptions: [
                { value: "", text: "All" },
                { value: 1, text: "Yes" },
                { value: 0, text: "No" }
            ],
            filterInput: {
                active: '',
                type: '',
            },
            showWarning: false,
        };
    },
    async created() {
        await this.getSeasonSetup();
        await this.getSeasonProducts();
    },
   
    methods: {
        updateModalData(updatedData) {
            this.modalData = { ...this.modalData, ...updatedData };
        },
        async getSeasonProducts() {
            try {
                this.toggleBusy();
                const response = await Ticketing.getSeasonProducts();
                let data = response.data.data;

                const types = [...new Set(data.map(item => item.type))];
                this.typeOptions = [{ value: "", text: "All Types" }, ...types.map(type => ({ value: type, text: type }))];

                this.tableData = data;
                this.applyFilters();
                this.toggleBusy();
            } catch (error) {
                this.toggleBusy();
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = [];
                this.filteredData = [];
                this.totalRows = 0;
            }
        },
        async getSeasonSetup() {
            try {
                this.toggleBusySetup();
                const response = await Ticketing.getSeasonSetup([]);
                let data = response.data.data;
                this.tableDataSetup = data;
                this.totalRowsSetup = data.length;
                this.checkActiveSeason();
                this.toggleBusySetup();
            } catch (error) {
                this.toggleBusySetup();
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableDataSetup = [];
                this.totalRowsSetup = 0;
            }
        },
        checkActiveSeason() {
            const today = new Date();
            const activeSeason = this.tableDataSetup.some(season => {
                const startSaleDate = new Date(season.start_sale_date);
                const endSaleDate = new Date(season.end_sale_date);
                return today >= startSaleDate && today <= endSaleDate;
            });

            if (!activeSeason) {
                this.showWarningMessage();
            } else {
                this.showWarning = false;
            }
        },
        showWarningMessage() {
            this.showWarning = true;
        },
        isSeasonActive(season) {
            const today = new Date();
            const startSaleDate = new Date(season.start_sale_date);
            const endSaleDate = new Date(season.end_sale_date);
            return today >= startSaleDate && today <= endSaleDate;
        },
        applyFilters() {
            this.filteredData = this.tableData.filter(item => {
                return (this.filterInput.type === '' || item.type === this.filterInput.type) &&
                       (this.filterInput.active === '' || item.active === this.filterInput.active);
            });
            this.totalRows = this.filteredData.length;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        callModalEditSeasonProduct(data) {
            this.modalData = { ...data };
            this.$bvModal.show("edit_season_product");
        },
        callModalEditSeasonSetup(data) {
            this.modalData = { ...data };
            this.$bvModal.show("edit_season_setup");
        },
        callModalAddSeasonSetup() {
            this.$bvModal.show("add_season_setup");
        },
        submitForm() {
            this.applyFilters();
        },
        resetFilters() {
            this.filterInput = {
                active: '',
                type: ''
            };
            this.applyFilters();
        },
        toggleBusy() {
            this.isBusy = !this.isBusy;
        },
        toggleBusySetup() {
            this.isBusySetup = !this.isBusySetup;
        },

async toggleActiveStatus(item) {
    try {
        const updatedStatus = item.active === 1 ? 0 : 1;
        const response = await Ticketing.updateSeasonProduct({ product_id: item.product_id, active: updatedStatus });
        
        if (response.data.error) {
            throw new Error(response.data.error.join(", "));
        }

        item.active = updatedStatus;

        this.$bvToast.toast('Active status updated successfully', {
            title: 'Success',
            variant: 'success',
            solid: true
        });
    } catch (error) {
        this.$bvToast.toast(`Failed to update active status: ${error.message}`, {
            title: 'Error',
            variant: 'danger',
            solid: true
        });
    }
}

    },
    
    middleware: "authentication",
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
            <div class="row">
                <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light float-end"
                           @click="callModalAddSeasonSetup">
                          <i class="mdi mdi-plus me-"></i>Add Season
                        </a>
                        <b-alert show dismissible variant="secondary" class=" mt-5"
                        ><i class="uil uil-info-circle font-size-20"></i>
                             Note: the app will only display tickets from active seasons and with a defined type (season, parking or bus)</b-alert
                        >
                        <b-alert class="mt-2"
                            variant="danger"
                            show
                            v-if="showWarning">
                            No active season!
                        </b-alert>
                      <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPageSetup"
                                                size="sm"
                                                :options="pageOptionsSetup"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                        <!-- Search -->
                            
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                            v-model="filterSetup"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                    ></b-form-input>
                                </label>
                                </div>
                            </div>
                        </div>
                        <!-- End search -->
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusySetup"
                                    :fields="fieldsSetup"
                                    :items="tableDataSetup"
                                    responsive="sm"
                                    :per-page="perPageSetup"
                                    :current-page="currentPageSetup"
                                    :sort-by.sync="sortBySetup"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filterSetup"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(new_sale_open)="data">
                                     <span>
                                       <i v-if="data.value === 'Y'" class="uil uil-check font-size-18 text-success"></i>
                                       <i v-else-if="data.value === 'N'" class="uil uil-times font-size-18 text-danger"></i>
                                     </span>
                                </template>

                                <template v-slot:cell(current_season)="data">
                                     <span>
                                       <i v-if="data.value === 1" class="uil uil-check font-size-18 text-success"></i>
                                       <i v-else-if="data.value === 0" class="uil uil-times font-size-18 text-danger"></i>
                                     </span>
                                </template>
                                <template v-slot:cell(start_sale_date)="data">
                                    <span :style="{ color: isSeasonActive(data.item) ? 'green' : '' }">
                                        {{ data.value }}
                                    </span>
                                </template>
                                <template v-slot:cell(end_sale_date)="data">
                                    <span :style="{ color: isSeasonActive(data.item) ? 'green' : '' }">
                                        {{ data.value }}
                                    </span>
                                </template>

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Season Setup" @click="callModalEditSeasonSetup(data.item)">
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>


                                </template>


                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPageSetup"
                                                :total-rows="totalRowsSetup"
                                                :per-page="perPageSetup"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       <PageHeader :title="titleProducts" :items="items" />
            <div class="row">
                <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="row mt-4">
                          <div class="col-md-12">
                            <div class="custom-accordion">
                              <a
                                  class="text-body fw-semibold pb-2 d-block"
                                  data-toggle="collapse"
                                  href="javascript: void(0);"
                                  role="button"
                                  aria-expanded="false"
                                  v-b-toggle.categories-collapse
                              >
                                <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                              </a>
                              <b-collapse visible id="categories-collapse">
                                <div class="card p-2 border shadow-none">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3">
                                            <label class="control-label form-label">Type</label>
                                                <b-form-select class="form-control" aria-describedby="type-feedback" :options="typeOptions" v-model="filterInput.type"></b-form-select>
                                        </div>
                                        <div class="col-sm-12 col-md-3">
                                            <label class="control-label form-label">Active</label>
                                            <b-form-select class="form-control" aria-describedby="active-feedback" :options="activeOptions" v-model="filterInput.active"></b-form-select>
                                        </div>
                                         <div class="col-sm-12 col-md-4" style="margin-top:2%;">
                                            <label class="control-label form-label"></label>
                                            <button type="button" class="btn btn-success w-lg" @click="submitForm" style="margin-bottom:0px; margin-right:5px; padding:5px">Filter</button>
                                          <button type="button" class="btn btn-primary w-lg" @click="resetFilters" style="margin-bottom:0px; padding:5px">Reset</button>

                                        </div>
                                         <div class="col-sm-12 col-md-1">
                                            <label class="control-label form-label"></label>

                                        </div>
                                    </div>

                                </div>
                              </b-collapse>
                            </div>
                          </div>
                        </div>

                      <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                        <!-- Search -->

                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                    ></b-form-input>
                                </label>
                                </div>
                            </div>
                        </div>
                        <!-- End search -->
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"

                                    :items="filteredData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >
                            <template #cell(active)="data">
    <div class="form-check form-switch form-switch-lg">
        <input :checked="data.item.active === 1" type="checkbox" class="form-check-input" @change="toggleActiveStatus(data.item)">
    </div>
</template>

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Season Product" @click="callModalEditSeasonProduct(data.item)">
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>


                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <ModalEditSeasonProduct :modalData="modalData" @update-modal-data="updateModalData" @onRefresh="getSeasonProducts()"></ModalEditSeasonProduct>
        <ModalEditSeasonSetup :modalData="modalData" @update-modal-data="updateModalData" @onRefresh="getSeasonSetup()"></ModalEditSeasonSetup>
        <ModalAddSeasonSetup @update-modal-data="updateModalData" @onRefresh="getSeasonSetup()"></ModalAddSeasonSetup>
    </Layout>
</template>